/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "About This Site"), "\n", React.createElement(_components.p, null, "Embodied Media is a repository for intermedia art projects created by John Crawford and colleagues since 1991, connecting diverse communities through embodied interaction. We aspire to do more than entertain people and create beauty for its own sake — we want to do these things in the service of relevant impact for social good. We explore new forms of expression enabled by emerging technical capabilities, including kinetic media, interactive software, social media, speculative design and XR (multisensory extended reality). Directly engaging with new technologies in the context of artistic production can spark development of products and services for positive change. We believe that design methodologies oriented to developing creative solutions should be paired with artistic methodologies that can reveal problems and critique existing structures."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
